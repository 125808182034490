import React from 'react'
import {css, useTheme} from "@emotion/react";


const HTMLContent = ({content}: { content: string }) => {
  const theme = useTheme();
  return (
    <span
      css={css`
        table {
          td {
            padding-top: 1em;

            &:last-of-type {
              padding-left: ${theme.margin.main}px;
            }

            &:first-of-type {
              padding-right: ${theme.margin.main}px;
            }
          }
        }
      `}
      dangerouslySetInnerHTML={{__html: content}}>
    </span>
  )
}

export default HTMLContent;
