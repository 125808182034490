import React, {ReactNode} from 'react'
import {css} from "@emotion/react";
import {mobileBreakpoint} from "../../constants";

interface Props {
  display: boolean
}

const Arrow = ({display} : Props) => {
  return (<div
    css={css`
      display: none;
      pointer-events: none;
      @media (max-width: ${mobileBreakpoint}px) {
        display: flex;
        position: absolute;
        justify-content: flex-end;
        align-items: center;
        right: 0;
        top: 0;
        height: 100%;
        width: 30px;
        background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(255,255,255,0.1) 50%);
        opacity: ${display ? 1 : 0};
        transition: all .3s linear;
      }
    `}
  >
    <svg
      css={css`
        overflow: visible;
        height: 50%;
        width: 30px;
        z-index: 11111111;
      `}
      preserveAspectRatio="none"
      viewBox="-21.302 -17.155 78.866 900.306" xmlns="http://www.w3.org/2000/svg">
      <path
        vectorEffect="non-scaling-stroke"
        d="M -0.619 3.086 L 36.881 434.597 L -0.619 862.91" fill="none" stroke="#CDCDCD" strokeWidth={2}/>
    </svg>
  </div>)
}

export default Arrow;
