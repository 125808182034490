import React, {ReactNode} from 'react'
import {css, useTheme} from "@emotion/react";
import {mobileBreakpoint} from "../../constants";

interface Props {
  rows: { title: string, content: ReactNode }[]
}

const DescriptionGrid: React.FC<Props> = ({rows}) => {
  const theme = useTheme();
  return (
    <>
      {rows.map(({title, content}) =>
        <div
          key={title}
          css={css`
            text-transform: uppercase;
            display: flex;
            flex-direction: row;
            margin-top: ${theme.margin.big}px;
          `}
        >
          <div css={css`
            min-width: 180px;
            flex-grow: 0;

            @media (max-width: ${mobileBreakpoint}px) {
              min-width: 70px;
            }

          `}>
            {title}
          </div>
          <div css={css`
            flex-grow: 2;
            color: ${theme.color.quaternary};
          `}>
            {content}
          </div>
        </div>)}
    </>
  )
}

export default DescriptionGrid;
