import React, {ReactNode} from 'react'
import {css, useTheme} from "@emotion/react";
import {mobileBreakpoint} from "../../constants";

interface Props {
  title: string,
  children: ReactNode
}

const DescriptionBlock: React.FC<Props> = ({children, title}) => {
  const theme = useTheme();
  return (
    <div css={css`
      text-transform: uppercase;
      margin-top: ${theme.margin.big}px;
      flex-grow: 2;

      & p:first-of-type {
        display: inline;
      }
    `}>
      <span css={css`
        color: ${theme.color.primary};
        margin-right: ${theme.margin.big * 3}px;
        @media (max-width: ${mobileBreakpoint}px) {
          margin-right: ${theme.margin.big}px;
        }
      `}>{title}</span> <span css={css`
      color: ${theme.color.quaternary};
    `}>{children}</span>
    </div>
  )
}

export default DescriptionBlock;
