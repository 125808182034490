import React from 'react'
import {SEO, SEOprops} from "./SEO";
import {useShopInfo} from "../../hooks/use-shop-info";

interface SEOProductProps extends SEOprops {
  productName: string,
  productImages: string[],
  productDescription: string,
  productBrand: string,
  available: boolean,
  price: string,
  productURL: string
}

const SEOProduct: React.FC<SEOProductProps> = ({
                                                 productName,
                                                 productImages,
                                                 productDescription,
                                                 productBrand,
                                                 available,
                                                 price,
                                                 productURL,
                                                 ...baseProps
                                               }) => {
  const {currency} = useShopInfo();
  return (
    <SEO
      {...baseProps}
    >
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org/",
          "@type": "Product",
          "name": "${productName}",
          "image": [
            ${productImages.map(i => `"${i}"`).join(',')}
          ],
          "description": "${productDescription}",
          "brand": {
            "@type": "Brand",
            "name": "${productBrand}"
          },
          "offers": {
          "@type": "Offer",
          "url": "${productURL}",
          "priceCurrency": "${currency}",
          "price": "${price}",
          "itemCondition": "https://schema.org/NewCondition",
          "availability": ${available ? `"https://schema.org/InStock"` : `"https://schema.org/OutOfStock"`}
        }
        }
        `}
      </script>

    </SEO>
  )
}

export default SEOProduct;
