import React from 'react'
import {ShopifyVariant} from "../types/data";
import {css, useTheme} from "@emotion/react";
import CheckMark from "./Toggle/CheckMark";

interface Props {
  variants: ShopifyVariant[],
  setVariant: (variant: ShopifyVariant) => void,
  selectedVariant: ShopifyVariant
}

const ColorSwatch = ({variants, setVariant, selectedVariant}: Props) => {
  const theme = useTheme();
  return (
    <div css={css`
      display: flex;
    `}>
      <div css={css`
        border: 1px solid ${theme.color.secondary};
        height: 1.5em;
        flex-grow: 0;
        font-size: ${theme.fontSize.main};
        display: flex;

      `}>
        {variants.map((v, idx) => (<button
          onClick={() => {
            setVariant(v)
          }}
          key={v.shopifyId}
          css={css`
            position: relative;
            background: ${v.metafield?.value};
            box-shadow: none;
            border: none;
            margin: 0;
            padding: 0;
            width: 50px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            ${idx < variants.length - 1 && css`
              border-right: 1px solid ${theme.color.secondary};
            `}
          `}
        >
          {v.shopifyId === selectedVariant.shopifyId && <CheckMark/>}
        </button>))}
      </div>
    </div>
  )
}

export default ColorSwatch;
