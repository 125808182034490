import React from 'react'
import {css} from "@emotion/react";

interface Props {

}

const CheckMark = () => {
  return (
    <svg
      css={css`
        pointer-events: none;
        user-select: none;
      `}
      xmlns="http://www.w3.org/2000/svg" width="24.406" height="16.669" viewBox="0 0 24.406 16.669">
      <path d="M17292.289,13119.331l7.738,7.739,13.84-13.841"
            transform="translate(-17290.875 -13111.815)" fill="none" stroke="#fff" strokeLinecap="round"
            strokeWidth="2"/>
    </svg>

  )
}

export default CheckMark;
